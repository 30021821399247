import React, {Component} from 'react';
import {Link} from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <div className={"footer"} id="footer">
                © Copyright by the Unknown Future Techno Collective 2022<br/>
                Background Animation by <a href={"https://www.instagram.com/fwiefred/"} target={"_blank"} rel="noreferrer">Frédéric Hermann</a><br/>
                Web Design by <a href={"https://www.maxerler.com"} target={"_blank"} rel="noreferrer">Max Erler</a><br/>
                <Link to={"/imprint"} target={"_blank"}>Imprint</Link>&nbsp;
                <Link to={"/privacy"} target={"_blank"}>Privacy</Link>
            </div>
        );
    }
}