import impression1 from "../../assets/impression_1.jpg";
import impression2 from "../../assets/impression_2.jpg";
import impression3 from "../../assets/impression_3.jpg";
import impression4 from "../../assets/impression_4.jpg";
import impression5 from "../../assets/impression_5.jpg";
import impression6 from "../../assets/impression_6.jpg";
import impression7 from "../../assets/impression_7.jpg";
import impression8 from "../../assets/impression_8.jpg";
import impression9 from "../../assets/impression_9.jpg";
import impression10 from "../../assets/impression_10.jpg";
import impression11 from "../../assets/impression_11.jpg";
import impression12 from "../../assets/impression_12.jpg";
import impression13 from "../../assets/impression_13.jpg";
import impression14 from "../../assets/impression_14.jpg";
import impression15 from "../../assets/impression_15.jpg";
import impression16 from "../../assets/impression_16.jpg";
import impression17 from "../../assets/impression_17.jpeg";
import impression18 from "../../assets/impression_18.jpeg";
import impression19 from "../../assets/impression_19.jpeg";
import impression20 from "../../assets/impression_20.jpeg";
import impression21 from "../../assets/impression_21.jpg";
import impression22 from "../../assets/impression_22.jpg";
import impression23 from "../../assets/impression_23.jpg";
import impression24 from "../../assets/impression_24.jpg";
import impression25 from "../../assets/impression_25.jpg";
import impression26 from "../../assets/impression_26.jpg";
import impression27 from "../../assets/impression_27.jpg";
import impression28 from "../../assets/impression_28.jpg";
import impression29 from "../../assets/impression_29.jpg";
import impression30 from "../../assets/impression_30.jpg";
import impression31 from "../../assets/impression_31.jpg";
import impression32 from "../../assets/impression_32.jpg";
import impression33 from "../../assets/impression_33.jpg";
import impression34 from "../../assets/impression_34.jpg";
import impression35 from "../../assets/impression_35.jpg";
import impression36 from "../../assets/impression_36.jpg";
import impression37 from "../../assets/impression_37.jpg";
import impression38 from "../../assets/impression_38.jpg";
import impression39 from "../../assets/impression_39.jpg";

export const photos = [
    {
        src: impression16,
        width: 2,
        height: 3,
        alt: "A crowd demonstrating for dance freedom behind a red sprinter escorted by police in Munich."
    },
    {
        src: impression38,
        width: 3,
        height: 2,
        alt: "TODO"
    },
    // {
    //     src: impression26,
    //     width: 2.25,
    //     height: 3,
    //     alt: "TODO"
    // },
    {
        src: impression3,
        width: 3,
        height: 2,
        alt: "Billboarded sprinter with the statement \"Everyone is young only once!\""
    },
    {
        src: impression21,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression6,
        width: 3,
        height: 2,
        alt: "A mixer."
    },
    {
        src: impression7,
        width: 2,
        height: 3,
        alt: "Three young adults hang a banner on a red Sprinter."
    },
    {
        src: impression23,
        width: 3,
        height: 2,
        alt: "TODO"
    },
    {
        src: impression12,
        width: 2,
        height: 3,
        alt: "A crowd demonstrating for dance freedom behind a red sprinter in Munich."
    },
    {
        src: impression24,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression30,
        width: 3,
        height: 2,
        alt: "TODO"
    },
    {
        src: impression14,
        width: 2,
        height: 3,
        alt: "A crowd demonstrating for dance freedom behind a red sprinter. Observer is in the middle of the crowd."
    },
    {
        src: impression28,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression15,
        width: 8,
        height: 5.33,
        alt: "A crowd demonstrating for dance freedom behind a red sprinter in Munich at another location."
    },
    // {
    //     src: impression29,
    //     width: 2.25,
    //     height: 3,
    //     alt: "TODO"
    // },
    {
        src: impression18,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression9,
        width: 3,
        height: 2,
        alt: "A crowd of young adults wearing FFP2 masks at Baldeplatz in Munich, accompanied by police."
    },
    // {
    //     src: impression32,
    //     width: 2.25,
    //     height: 3,
    //     alt: "TODO"
    // },
    {
        src: impression5,
        width: 2,
        height: 3,
        alt: "Freddy at the mixer in the Sprinter at a dance demonstration in Munich."
    },
    {
        src: impression34,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression4,
        width: 3,
        height: 2,
        alt: "A crowd in the rain at the Theresienwiese."
    },
    {
        src: impression37,
        width: 3,
        height: 2,
        alt: "TODO"
    },
    {
        src: impression2,
        width: 2,
        height: 3,
        alt: "Five young adults on a placarded fire truck at a demonstration. The banner reads \"We love to dance with you\"."
    },
    {
        src: impression39,
        width: 3,
        height: 2,
        alt: "TODO"
    },
    {
        src: impression19,
        width: 5,
        height: 6.6,
        alt: "TODO"
    },
    {
        src: impression1,
        width: 3,
        height: 2,
        alt: "Moritz and Pierre from behind in front of the DJ booth on a fire truck."
    },
    {
        src: impression20,
        width: 5,
        height: 6.6,
        alt: "TODO"
    },
    {
        src: impression17,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression35,
        width: 3,
        height: 2,
        alt: "TODO"
    },
    {
        src: impression8,
        width: 3,
        height: 2,
        alt: "A crowd of young adults wearing FFP2 masks at Baldeplatz in Munich."
    },
    {
        src: impression22,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    // {
    //     src: impression33,
    //     width: 2.25,
    //     height: 3,
    //     alt: "TODO"
    // },
    {
        src: impression10,
        width: 2,
        height: 3,
        alt: "Moritz, Pierre and Cosi in the hold of a red Sprinter. A mixing console and sound system are installed inside."
    },
    {
        src: impression36,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression11,
        width: 3,
        height: 2,
        alt: "A crowd of young adults behind a red and placarded sprinter on Baldeplatz in Munich."
    },
    // {
    //     src: impression27,
    //     width: 2.25,
    //     height: 3,
    //     alt: "TODO"
    // },
    {
        src: impression31,
        width: 2,
        height: 3,
        alt: "TODO"
    },
    {
        src: impression13,
        width: 8,
        height: 5.33,
        alt: "A crowd demonstrating for dance freedom behind a red sprinter in Munich from another angle."
    },
    // {
    //     src: impression25,
    //     width: 2.25,
    //     height: 3,
    //     alt: "TODO"
    // }
];