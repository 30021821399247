import React, {Component} from "react";

export default class NameInput extends Component {

    constructor(props) {
        super(props);
        this.state = {name: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({name: event.target.value});
    }

    render() {
        return (
            <input type="text" name="name" placeholder={"Name"} onChange={this.handleChange} required={'required'}/>
        )
    }
}