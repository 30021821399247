import React, {Component} from "react";

export default class EmailInput extends Component {

    constructor(props) {
        super(props);
        this.state = {email: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
        // this.props.email(event.target.value)
    }

    render() {
        return (
            <input type="email" name="email" placeholder={"Email"} onChange={this.handleChange}  required={'required'}/>
        )
    }
}