import React, {Component} from 'react';

export default class Paypal extends Component {
    render() {
        return (
            <div ref={this.props.refs.Paypal} id="paypal">
                <div className={"row"}>
                    <div className={"col"}>
                        <h1>Support Us</h1>
                        Send us your donation via PayPal to contact@unknown-future.de.
                    </div>
                </div>
            </div>
        );
    }
}