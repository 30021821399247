import React, {Component} from "react";

export default class MessageInput extends Component {

    constructor(props) {
        super(props);
        this.state = {message: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({message: event.target.value});
    }

    render() {
        return (
            <textarea name="message" placeholder={"Message"} onChange={this.handleChange}  required={'required'}/>
        )
    }
}