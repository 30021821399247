import React, {Component} from 'react';
import ContactForm from "./contactForm";

export default class Contact extends Component {
    componentDidMount () {
        const script = document.createElement("script");

        script.src = "https://cdn.jsdelivr.net/npm/emailjs-com@2/dist/email.min.js";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <div ref={this.props.refs.Contact} id="contact">
                <div className={"row"}>
                    <div className={"col"}>
                        <h1>Contact</h1>
                        <div className={'contact-form'}>
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}