import React, {Component} from 'react';

export default class DesktopNavigation extends Component {
    render() {
        return (
            <nav className={this.props.sticky ? 'navbar navbar--sticky' : 'no-navbar'} id='mainNav'>
                <div onClick={() => this.props.goTo('Landing')} className='navbar--logo-holder'>
                    <img src={'logo_fox.png'} alt='logo' className='navbar--logo'/>
                    <h1 className={this.props.sticky ? 'navbar--logo-text-sticky' : 'navbar--logo-text'}>Unknown
                        Future</h1>
                </div>
                <ul className='navbar--link'>
                    <div onClick={() => this.props.goTo('Landing')}>
                        <div
                            className={(this.props.activeElement === 'Landing' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Home
                        </div>
                    </div>
                    <div onClick={() => this.props.goTo('About')}>
                        <div
                            className={(this.props.activeElement === 'About' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Collective
                        </div>
                    </div>
                    <div onClick={() => this.props.goTo('Impression')}>
                        <div
                            className={(this.props.activeElement === 'Impression' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Impressions
                        </div>
                    </div>
                    <div onClick={() => this.props.goTo('Contact')}>
                        <div
                            className={(this.props.activeElement === 'Contact' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Contact
                        </div>
                    </div>
                    <div onClick={() => this.props.goTo('Paypal')}>
                        <div
                            className={(this.props.activeElement === 'Paypal' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Support Us
                        </div>
                    </div>
                    <div
                        className={this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item'}>
                        <a href={"https://shop.unknown-future.de"} rel="noreferrer">Shop</a>
                    </div>
                </ul>
            </nav>
        );
    }
}