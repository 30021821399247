import React, {Component} from 'react';
import sloMo from '../../assets/sloMo.jpg';
import ySanity from '../../assets/ySanity.jpg';
import sslv from '../../assets/sslv.png';
import dsjkAdou from '../../assets/dsjkAdou.jpg';
import cruisima from '../../assets/cruisima.jpg';
import lora from '../../assets/lora.png';

export default class About extends Component {
    render() {
        return (
            <div ref={this.props.refs.About} id="about">
                <div className={"row"}>
                    <div className={"col"}>
                        <h1>Our Family</h1>
                        {/*<p>*/}
                        {/*    Hello friend! We are Unknown Future – a Munich based techno collective. Dancing is our panacea, which is why we regularly protest for it. This is our family:*/}
                        {/*</p>*/}
                        <div className={"members"}>
                            <div className={"member"}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img alt={"profile picture of Moritz Weber"} src={sloMo}/>
                                <div className={"member-info"}>
                                    Moritz Weber – SloMo
                                </div>
                            </div>
                            <div className={"member"}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img alt={"profile picture of Pierre O'Reilly"} src={ySanity}/>
                                <div className={"member-info"}>
                                    Pierre O'Reilly – Cavan
                                </div>
                            </div>
                            <div className={"member"}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img alt={"profile picture of Freddy Camara"} src={dsjkAdou}/>
                                <div className={"member-info"}>
                                    Freddy Camara – Adou
                                </div>
                            </div>
                            <div className={"member"}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img alt={"profile picture of Cosima Talhof"} src={cruisima}/>
                                <div className={"member-info"}>
                                    Cosima Talhof – Cosi
                                </div>
                            </div>
                            <div className={"member"}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img alt={"profile picture of Martin Müller "} src={sslv}/>
                                <div className={"member-info"}>
                                    Martin Mueller – SSLV
                                </div>
                            </div>
                            <div className={"member"}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img alt={"profile picture of Laura Kristen"} src={lora}/>
                                <div className={"member-info"}>
                                    Laura Kristen – Lora
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        <h1>Hottest Groove</h1>
                        {/*More music is on the way, so stay tuned! Check out our <a href={"https://soundcloud.com/martin-mueller-903906930"} target={"_blank"} rel="noreferrer">SoundCloud</a> and <a href={"https://instagram.com/unknown_future_kollektiv"} target={"_blank"} rel="noreferrer">Insta</a>!*/}
                        {/*<iframe title={"soundcloud-frame-10"} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1345717840&color=%23800000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>*/}
                        <iframe title={"soundcloud-frame-6"} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1522284418&color=%23800000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
                        <iframe title={"soundcloud-frame-7"} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1477440781&color=%23800000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
                        <iframe title={"soundcloud-frame-8"} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1318089769&color=%23800000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
                        <iframe title={'soundcloud-frame-5'} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1231278343&color=%23b40000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
                        <iframe title={'soundcloud-frame-4'} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1248003928&color=%23b40000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
                        {/*<iframe title={'soundcloud-frame-9'} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1423450198&color=%23800000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
                        <iframe title={'soundcloud-frame-3'} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1263356323&color=%23b40000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>*/}
                        {/*<iframe title={'soundcloud-frame-2'} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"*/}
                        {/*        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1248437524&color=%23b40000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>*/}
                        {/*<iframe title={'soundcloud-frame-1'} className={"react-player"} scrolling="no" frameBorder="no" allow="autoplay"*/}
                        {/*        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1225208641&color=%23b40000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>*/}
                    </div>
                    <div className={"col"}>
                        <h1>News Ticker</h1>
                        <h2>04/12/2022</h2>
                        <p>
                            We are going to start our announced podcast series. Look forward to some great sets.
                            Check out our <a href={"https://www.instagram.com/unknown_future_kollektiv/"} target={"_blank"} rel="noreferrer">Instagram</a> and <a href={"https://soundcloud.com/unknown-future-kollektiv"} target={"_blank"} rel="noreferrer">Soundcloud</a> for the latest releases.
                            Let's groove the christmas time together!
                        </p>
                        <h2>27/11/2022</h2>
                        <p>
                                From now on <a href={"https://www.instagram.com/lorraaxy/"} target={"_blank"} rel="noreferrer">@lorraaxy</a> is part of unknown future!
                            Lora is a DJ from Hannover. She has been DJing since the beginning of the year and plays groovy fast techno. She has been in the scene for years and decided to start her DJ career.
                            We are very happy to work and grow with her and to welcome her as a new family member!
                        </p>
                        <h2>02/09/2022</h2>
                        <p>In cooperation with many other actors like Kollektivis e.V., Schallfabrik Audio and the organizers of Syndikat Sessions we got the permission for the first outdoor rave on urban open spaces.
                            Thanks to the city funding we were able to cover the immensely high costs and now have the opportunity to offer you cheaper tickets. The income will be reinvested for future (free) events.
                        </p>
                        <h2>27/05/2022</h2>
                        <p>We had an amazing Unknown Future birthday rave! Big thanks to our special guests <a href={"https://soundcloud.com/flitstechno"} target={"_blank"} rel="noreferrer">flits</a> and <a href={"https://soundcloud.com/ballsbaile/balls-01-bw"} target={"_blank"} rel="noreferrer">bw</a>!
                            We did it! We raved the goddamn soul out of the "Kulturprojekt Allach". Fast and hard techno? Not with us, we follow the danceable groove baby.
                        </p>
                        <h2>01/04/2022</h2>
                        <p>We did our first indoor rave "Unknown Future Crew invites// Latex Dreams, PT-2, Nox and Linz".
                            It was amazing – nice people and good groove! A perfect opening of the rave season in 2022.
                            Big thanks to all of our rave bunnies &lt;3!</p>
                        <h2>06/02/2022</h2>
                        <p>We protested together for more free spaces in Munich and Germany! Thanks for the participation and the good mood!
                            We started between 15:00 and 15:30 at Baldeplatz and marched together to the Theresienwiese.
                            There was our closing rally (it was a rave indeed) between 16:00 and 19:00.</p>
                    </div>
                </div>
            </div>
        );
    }
}