import React, {Component} from 'react';
// import backgroundVideo from '../../assets/background.mp4';
import backgroundVideo from '../../assets/animation_85.mp4';
import backgroundVideoMobile from '../../assets/animation_85_mobile.mp4';
/*import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from "./audioPlayer";*/

export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            muted: true,
            playing: false
        };
        this.handleMuteButtonClick = this.handleMuteButtonClick.bind(this);
    }

    handleMuteButtonClick() {
        if (!this.state.playing) {
            this.setState(() => ({
                playing: true
            }));
            document.getElementById('audio-element').load();
            document.getElementById('audio-element').play();
        }

        this.setState(stateBefore => ({
            muted: !stateBefore.muted,
        }));
        const stateBefore = document.getElementById('audio-element').muted;
        document.getElementById('audio-element').muted = !stateBefore;
    }

    render() {
        return (
            <div ref={this.props.element}>
                <div ref={this.props.refs.Landing} id="landing" className={"intro"}>
                    <div className="intro-content display-table">
                        <div className={"table-cell"}>
                            {/*<img className={'unknown-future-logo-big'} src={""} alt={"The Unknown Future Logo (pulsating)"}/>*/}
                            {/*<AudioPlayer />*/}
                            <h1 className="intro-title">
                                {/*<button id={"audio-control"} className={"landing--background-audio-control"} onClick={this.handleMuteButtonClick}>
                                    {this.state.muted ? <FontAwesomeIcon icon={faVolumeMute}/> : <FontAwesomeIcon icon={faVolumeUp} />}
                                </button>*/}
                                &nbsp;Unknown Future
                            </h1>
                            <div className={"background-overlay"}>
                                <video className={"landing--background-video"} muted autoPlay playsInline>
                                    <source src={backgroundVideo} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                                <video className={"landing--background-video-mobile"} muted autoPlay playsInline>
                                    <source src={backgroundVideoMobile} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}