import React, {Component} from 'react';
import Landing from "./landing/landing";
import Footer from "./footer/footer";
import About from "./about/about";
import Contact from "./contact/contact";
import Impression from "./impression/impression";
import Paypal from "./paypal/paypal";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export default class Content extends Component {
    render() {
        // do this inside render so one can rest assured google analytics is only loaded if consent
        const firebaseConfig = {
            apiKey: "AIzaSyCg8OnqHUtvrRAyJjURQ3xibuyLUh7DDWA",
            authDomain: "unkown-future-website.firebaseapp.com",
            projectId: "unkown-future-website",
            storageBucket: "unkown-future-website.appspot.com",
            messagingSenderId: "699647373006",
            appId: "1:699647373006:web:6a55e2f89ef03196ff2a9f",
            measurementId: "G-XBD9BK8FEW"
        };
        const app = initializeApp(firebaseConfig);
        getAnalytics(app);

        return (
            <div id={"content"}>
                <Landing element={this.props.element} refs={this.props.refs}/>
                <About refs={this.props.refs} />
                <Impression refs={this.props.refs} />
                <Contact refs={this.props.refs} />
                <Paypal refs={this.props.refs} />
                <Footer />
            </div>
        );
    }
}