import './App.scss';
import React from "react";
import {BrowserRouter} from "react-router-dom";
import useSticky from "./hooks/useSticky";
import Routing from "./components/header/routing";

function App() {
  const {isSticky, element} = useSticky();
  return (
      <div className="App" id="website">
        <BrowserRouter>
          <Routing element={element} isSticky={isSticky}/>
        </BrowserRouter>
      </div>
  );
}

export default App;
