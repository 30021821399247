import React, {Component} from 'react';
import Gallery from "react-photo-gallery";
import {photos} from "./photos";

export default class Impression extends Component {
    render() {
        return (
            <div ref={this.props.refs.Impression} id="impression">
                <div className={"row"}>
                    <div className={"col"}>
                        <h1>Impressions</h1>
                        <Gallery photos={photos}/>
                    </div>
                </div>
            </div>
        );
    }
}