import React, {Component} from 'react';

export default class Imprint extends Component {
    render() {
        return (
            <div className={"legal"}>
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Martin Müller<br />
                    Am Klosterfeld 6<br />
                    85235 Odelzhausen</p>

                <h2>Kontakt</h2>
                <p>Telefon: +49 162 2891943<br />
                    E-Mail: contact@unknown-future.de</p>

                <h2>Redaktionell verantwortlich</h2>
                <p>Martin Müller<br />
                    Am Klosterfeld 6<br />
                    85235 Odelzhausen</p>

                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
            </div>
        );
    }
}