import React, {Component} from 'react';
import {Link, Route, Switch} from "react-router-dom";
import ScrollNavigation from "react-single-page-navigation";
import Imprint from "../legal/imprint";
import Privacy from "../legal/privacy";
import Content from "../content";
import Landing from "../landing/landing";
import About from "../about/about";
import Contact from "../contact/contact";
import Impression from "../impression/impression";
import Paypal from "../paypal/paypal";
import Navigation from "./navigation";
import {isConsent, setCookie} from "../../cookies";

export default class Routing extends Component {
    constructor(props) {
        super(props);
        this.state = {consent: false};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({consent: true});
        console.log("Set user consent cookie for until the session expires");
        setCookie("consent", "true");
    }

    render() {
        let page;
        if (!isConsent()) {
            page = <div className={"consent"} id={"consent"}>
                <h1>Unknown Future</h1>
                <h2>Important Information</h2>
                This website uses cookies and other tracking technologies to analyze user behaviour:
                <ul>
                    <li>Google Analytics (<a href={"https://policies.google.com/privacy"} target={"_blank"} rel="noreferrer">Privacy Terms</a>)</li>
                    <li>Consent Cookie (nothing else than "consent=true;" if you agree with these terms)</li>
                </ul>
                This website dynamically loads content from third parties:
                <ul>
                    <li>EmailJS (<a href={"https://www.emailjs.com/legal/privacy-policy/"} target={"_blank"} rel="noreferrer">Privacy Terms</a>)</li>
                    <li>Soundcloud (<a href={"https://soundcloud.com/pages/privacy"} target={"_blank"} rel="noreferrer">Privacy Terms</a>)</li>
                </ul>
                This website is hosted at third parties:
                <ul>
                    <li>Domains and Content at Google Firebase (<a href={"https://firebase.google.com/support/privacy"} target={"_blank"} rel="noreferrer">Privacy Terms</a>)</li>
                    <li>Domains at Ionos SE (<a href={"https://www.ionos.de/terms-gtc/datenschutzerklaerung/"} target={"_blank"} rel="noreferrer">Privacy Terms</a>)</li>
                </ul>

                Read our <a href={"/privacy"} target={"_blank"} rel="noreferrer">Privacy Policy</a> first for all information.<br/><br/>

                <button className={"consent-button"} onClick={this.handleSubmit}>I am OKAY with this!</button>
            </div>
        } else {
            page = <div id="routing">
                <Switch>
                    <Route exact path="#landing" component={Landing}/>
                    <Route exact path="#about" component={About}/>
                    <Route exact path="#contact" component={Contact}/>
                    <Route exact path="#impression" component={Impression}/>
                    <Route exact path="#paypal" component={Paypal}/>
                    <Route exact path={'/imprint'} render={() => (<Imprint/>)}/>
                    <Route exact path={'/privacy'} render={() => (<Privacy/>)}/>
                    <Route render={() => (
                        <ScrollNavigation elements={{Landing: {}, About: {}, Contact: {}, Impression: {}, Paypal: {}}}
                                          offset={-100}>
                            {({refs, activeElement, goTo}) => (
                                <div>
                                    <Navigation
                                        refs={refs}
                                        activeElement={activeElement}
                                        goTo={goTo}
                                        isSticky={this.props.isSticky}
                                    />
                                    <Content
                                        element={this.props.element}
                                        refs={refs}
                                    />
                                </div>
                            )}
                        </ScrollNavigation>
                    )}/>
                    {/* default route is always the app itself */}
                </Switch>
            </div>
        }
        return page;
    }
}