import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import './fonts/Zeniq/Zeniq.ttf';
import "./fonts/Audiowide/Audiowide-Regular.ttf"

/*const firebaseConfig = {
    apiKey: "AIzaSyCg8OnqHUtvrRAyJjURQ3xibuyLUh7DDWA",
    authDomain: "unkown-future-website.firebaseapp.com",
    projectId: "unkown-future-website",
    storageBucket: "unkown-future-website.appspot.com",
    messagingSenderId: "699647373006",
    appId: "1:699647373006:web:6a55e2f89ef03196ff2a9f",
    measurementId: "G-XBD9BK8FEW"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);*/

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
