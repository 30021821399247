import React, {Component} from "react";

export default class SubjectInput extends Component {

    constructor(props) {
        super(props);
        this.state = {subject: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({subject: event.target.value});
        // this.props.subject(event.target.value);
    }

    render() {
        return (
            <input type={'text'} name={'subject'} placeholder={"Subject"} onChange={this.handleChange}  required={'required'}/>
        )
    }
}